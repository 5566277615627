import 'bootstrap'
import '@popperjs/core'
import IMask from 'imask'
import htmx from 'htmx.org'

window.htmx = htmx

document.addEventListener('DOMContentLoaded', () => {
    const doc = document.getElementById('id_doc')
    if (doc) { IMask(doc, { mask: [
            { mask: '000.000.000-00' },
            { mask: '00.000.000/0000-00' }
        ]})
    }

    // const fone = document.getElementById('id_celular')
    // if (fone) { IMask(fone, { mask: [
    //         { mask: '(00) 0000-0000' },
    //         { mask: '(00) 00000-0000' }
    //     ]})
    // }

    const fones = document.querySelectorAll('.fone')
    if (fones) {
        fones.forEach(f => {
            IMask(f, [
                { mask: '(00) 0000-0000' },
                { mask: '(00) 00000-0000' }
            ])
        })
    }

    // const celular = document.querySelector('.celular')
    // if (celular) {
    //     IMask(celular, { mask: '(00) 00000-0000' })
    // }

    const cep = document.getElementById('id_cep')
    if (cep) {
        IMask(cep, { mask: '00000-000' })
    }


    window.buscarCEP = function(e) {
        if (/^\d{5}-\d{3}$/.test(e.target.value)) {
            const cep = e.target.value
            fetch('https://viacep.com.br/ws/' + cep + '/json/')
                .then(response => response.json() )
                .then(data => {
                    if (data.erro) {
                        alert('CEP não encontrado.')
                    }
                    else {
                        document.getElementById('id_endereco').value = data.logradouro
                        document.getElementById('id_bairro').value = data.bairro
                        document.getElementById('id_cidade').value = data.localidade
                        document.getElementById('id_estado').value = data.uf
                    }
                })
        }
    }
})

